import { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GuardedRoute as Route } from 'react-router-guards';
import { Switch, Redirect } from 'react-router-dom';
import {
  browserHistory as history,
  isFeatureActive,
} from '@electricjs/utils/helpers';
import { useFeatureFlags } from '@electricjs/utils/hooks';
import { userPermissionsSelector } from '@turbine/redux/selectors/userPermissionsSelector';
import { getRedirectURLFromConfig, REDIRECTS } from './redirectsConfig';
import ClearCacheComponent from './clearCacheComponent';
import { ENROLLMENT_ROUTES } from '@turbine/components/CustomerEnrollment';
import FEATURE_FLAG_CONFIG, {
  TURBINE_PEOPLE_EDIT_USER_PROFILES,
  TURBINE_REPORTS_PAGE_MALWAREBYTES,
  TURBINE_REPORTS_ANNUAL_SUMMARY,
  TURBINE_REPORTS_PAGE_XBOARDING,
  TURBINE_REPORTS_SUPPORTED_EMPLOYEES,
  TURBINE_PEOPLE_USER_PROFILES,
} from '@turbine/constants/featureFlags';
import { customerPlanSelector } from '@turbine/redux/selectors/customerPlanSelector';
import { PLANS } from '@turbine/constants/customerPlans';
import { useSession } from '@turbine/helpers/hooks';
import { useCustomer } from '@turbine/helpers/hooks/useCustomer';
import { LoadingScreen } from '@turbine/@arc/recipes/LoadingScreen';
import { BasePageLayout } from '@electricjs/arc';
import RouteErrorBoundary from './RouteErrorBoundary';

// This is a temporary "page layout" for pages that need specialized layouts
const EmptyPageLayout = ({ children }) => <>{children}</>;

const People = lazy(() => import('@turbine/pages/People'));
const PeopleUserProfile = lazy(() =>
  import('@turbine/pages/PeopleUserProfile')
);
const PeopleUserProfileEdit = lazy(() =>
  import('@turbine/pages/PeopleUserProfileEdit')
);
const BoardingStatus = lazy(() => import('@turbine/pages/BoardingStatus'));
const NewOnboarding = lazy(() => import('@turbine/pages/NewOnboarding'));
const NewOffboarding = lazy(() => import('@turbine/pages/NewOffboarding'));
const OAuth = lazy(() => import('@turbine/pages/OAuth'));
const Configuration = lazy(() => import('@turbine/pages/CompanyConfiguration'));
const RequestsData = lazy(() =>
  import('@turbine/pages/Reporting/RequestsData')
);
const XBoardings = lazy(() => import('@turbine/pages/Reporting/XBoardings'));
const HardwareRequests = lazy(() =>
  import('@turbine/pages/Reporting/HardwareRequests')
);
const Malwarebytes = lazy(() =>
  import('@turbine/pages/Reporting/Malwarebytes')
);
const SupportedEmployees = lazy(() =>
  import('@turbine/pages/Reporting/SupportedEmployees')
);
const AnnualSummary = lazy(() =>
  import('@turbine/pages/Reporting/AnnualSummary')
);
const ApplicationDetailsWrapper = lazy(() =>
  import(
    '@turbine/pages/Marketplace/components/ApplicationDetails/ApplicationDetails.wrapper'
  )
);
const Marketplace = lazy(() => import('@turbine/pages/Marketplace'));
const DeviceManagement = lazy(() => import('@turbine/pages/DeviceManagement'));
const ITScoreCardCalc = lazy(() => import('@turbine/pages/ITScoreCardCalc'));
const ITScoreDashboard = lazy(() => import('@turbine/pages/ITScoreDashboard'));
const EnrolledDeviceDetail = lazy(() =>
  import(
    '@turbine/pages/DeviceManagement/DeviceDetailedView/EnrolledDeviceDetailedView'
  )
);
const StoredDeviceDetail = lazy(() =>
  import(
    '@turbine/pages/DeviceManagement/DeviceDetailedView/StoredDeviceDetailedView'
  )
);
const Applications = lazy(() => import('@turbine/components/Applications'));
const AppConfiguration = lazy(() =>
  import('@turbine/components/Applications/TrayAppConfiguration')
);
const PageNotFound = lazy(() => import('@turbine/components/PageNotFound'));
const Request = lazy(() => import('@turbine/components/Request'));
const UserManagement = lazy(() => import('@turbine/components/UserManagement'));
const Locations = lazy(() => import('@turbine/components/Locations'));
const Approvals = lazy(() => import('@turbine/components/Approvals'));
const ShopHardware = lazy(() =>
  import('@turbine/pages/ShopHardware/ShopHardware')
);
const CompanyPurchase = lazy(() =>
  import('@turbine/pages/ShopHardware/ProvisioningOptions/CompanyPurchase')
);
const ForElectricInventoryPurchase = lazy(() =>
  import(
    '@turbine/pages/ShopHardware/ProvisioningOptions/ForElectricInventoryPurchase'
  )
);
const EmployeePurchase = lazy(() =>
  import('@turbine/pages/ShopHardware/ProvisioningOptions/EmployeePurchase')
);

const EnrollmentRoute = {
  path: ENROLLMENT_ROUTES.path,
  component: ENROLLMENT_ROUTES.component(),
  meta: { auth: true },
};

export const OnOffboardingRoute = {
  pageTitle: 'People',
  parentPath: '/onoffboardings',
  path: '/onoffboardings',
  component: People,
  meta: {
    auth: true,
    plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.LITE, PLANS.PREMIUM],
    permissionsNeeded: [
      'turbine:offboardings:read',
      'turbine:onboardings:read',
    ],
    headerProps: {
      title: 'People',
      subtitle: 'Your employee information from onboarding to offboarding',
    },
  },
};
const applicationRoutes = [
  {
    component: RequestsData,
    parentPath: '/dashboard',
    path: '/dashboard',
    meta: { auth: true, plansNeeded: [PLANS.BASIC, PLANS.CORE] },
  },
  {
    component: Configuration,
    parentPath: '/configuration',
    path: '/configuration',
    meta: {
      auth: true,
      plansNeeded: [PLANS.BASIC, PLANS.CORE],
      headerProps: {
        title: 'Configuration',
        subtitle:
          "Set up your company's default settings to expedite onboardings and offboardings",
      },
    },
  },
  {
    component: Locations,
    parentPath: '/locations',
    path: '/locations',
    meta: {
      auth: true,
      plansNeeded: [PLANS.BASIC, PLANS.CORE],
      headerProps: {
        title: 'Locations',
        subtitle: "Manage your company's locations",
      },
    },
  },
  {
    parentPath: '/user-management',
    path: '/user-management',
    component: UserManagement,
    meta: {
      auth: true,
      headerProps: {
        title: 'Role Settings',
        subtitle:
          'Provide and review access to the Electric platform for your employees',
      },
    },
  },
  {
    pageTitle: 'Enrolled Device',
    parentPath: '/device-mgmt',
    path: '/device-mgmt/enrolled-device/:id',
    component: EnrolledDeviceDetail,
    meta: { auth: true, permissionsNeeded: ['turbine:reports:read'] },
  },
  {
    pageTitle: 'Device Management',
    parentPath: '/device-mgmt',
    path: '/device-mgmt',
    component: DeviceManagement,
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:reports:read'],
      plansNeeded: [PLANS.LITE, PLANS.BASIC, PLANS.CORE],
      headerProps: {
        title: 'Device Management',
        subtitle: "Maintain your company's devices and device inventory",
      },
    },
  },
  {
    pageTitle: 'Marketplace',
    parentPath: '/marketplace',
    path: '/marketplace',
    component: Marketplace,
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:reports:read'],
      plansNeeded: [PLANS.LITE, PLANS.BASIC, PLANS.CORE],
      headerProps: {
        title: 'Marketplace',
        subtitle: 'Explore applications that keep your business safe',
      },
    },
  },
  {
    pageTitle: 'Marketplace',
    parentPath: '/marketplace',
    path: '/marketplace/:id',
    component: ApplicationDetailsWrapper,
    meta: { auth: true, permissionsNeeded: ['turbine:reports:read'] },
  },
  {
    pageTitle: 'Stored Devices',
    parentPath: '/device-mgmt',
    path: '/device-mgmt/stored-device/:id',
    component: StoredDeviceDetail,
    meta: { auth: true, permissionsNeeded: ['turbine:reports:read'] },
  },
  {
    parentPath: '/applications',
    path: '/applications',
    component: Applications,
    meta: {
      auth: true,
      headerProps: {
        title: 'Applications',
        subtitle: 'Manage the applications your company and employees use',
      },
    },
  },
  {
    parentPath: '/applications',
    path: '/applications/configure/:application',
    component: AppConfiguration,
    pageLayout: EmptyPageLayout,
    meta: { auth: true },
  },
  {
    parentPath: '/approvals',
    path: '/approvals',
    component: Approvals,
    meta: {
      auth: true,
      plansNeeded: [PLANS.LITE, PLANS.BASIC, PLANS.CORE],
      headerProps: {
        title: 'Approvals',
        subtitle: 'Review pending and past application approvals',
      },
    },
  },
  {
    parentPath: '/it-score-card',
    path: '/it-score-card',
    component: ITScoreDashboard,
    meta: {
      auth: true,
      plansNeeded: [PLANS.LITE, PLANS.BASIC, PLANS.CORE],
      headerProps: {
        title: 'IT Scorecard',
        subtitle: 'An overview of your company’s IT health',
      },
    },
  },
  {
    parentPath: '/it-score-card',
    path: '/it-score-card/calculation',
    component: ITScoreCardCalc,
    meta: { auth: true },
  },
  {
    parentPath: '/shop-hardware',
    path: '/shop-hardware',
    component: ShopHardware,
    meta: {
      auth: true,
      plansNeeded: [PLANS.LITE, PLANS.BASIC, PLANS.CORE],
    },
  },
  {
    parentPath: '/shop-hardware',
    path: '/shop-hardware/company',
    component: CompanyPurchase,
    meta: {
      auth: true,
      plansNeeded: [PLANS.LITE, PLANS.BASIC, PLANS.CORE],
    },
  },
  {
    parentPath: '/shop-hardware',
    path: '/shop-hardware/for-electric-inventory',
    component: ForElectricInventoryPurchase,
    meta: {
      auth: true,
      plansNeeded: [PLANS.LITE, PLANS.BASIC, PLANS.CORE],
    },
  },
  {
    parentPath: '/shop-hardware',
    path: '/shop-hardware/employee',
    component: EmployeePurchase,
    meta: {
      auth: true,
      plansNeeded: [PLANS.LITE, PLANS.BASIC, PLANS.CORE],
    },
  },
  {
    parentPath: '/clear',
    path: '/clear',
    component: ClearCacheComponent,
  },
  {
    component: Request,
    parentPath: '/request/:id',
    path: '/request/:id',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:admin:create', 'turbine:reports:read'],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    component: HardwareRequests,
    parentPath: '/hardware',
    path: '/hardware',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:admin:create', 'turbine:reports:read'],
      plansNeeded: [PLANS.BASIC, PLANS.CORE],
    },
  },
  {
    component: XBoardings,
    parentPath: '/xboarding',
    path: '/xboarding',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:admin:create', 'turbine:reports:read'],
      featureFlagsNeeded: [TURBINE_REPORTS_PAGE_XBOARDING],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    component: Malwarebytes,
    parentPath: '/malwarebytes',
    path: '/malwarebytes',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:admin:create', 'turbine:reports:read'],
      featureFlagsNeeded: [TURBINE_REPORTS_PAGE_MALWAREBYTES],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    component: SupportedEmployees,
    parentPath: '/supported',
    path: '/supported',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:admin:create', 'turbine:reports:read'],
      featureFlagsNeeded: [TURBINE_REPORTS_SUPPORTED_EMPLOYEES],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    component: AnnualSummary,
    parentPath: '/summary',
    path: '/summary',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:admin:create', 'turbine:reports:read'],
      featureFlagsNeeded: [TURBINE_REPORTS_ANNUAL_SUMMARY],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    component: BoardingStatus,
    parentPath: '/onoffboardings',
    path: '/:boardingType/status/:boardingId',
    meta: {
      auth: true,
      permissionsNeeded: [
        'turbine:offboardings:read',
        'turbine:onboardings:read',
      ],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    component: NewOnboarding,
    pageLayout: EmptyPageLayout,
    parentPath: '/onoffboardings',
    path: '/onoffboardings/onboarding/new',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:onboardings:create'],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    component: NewOnboarding,
    pageLayout: EmptyPageLayout,
    parentPath: '/onoffboardings',
    path: '/onoffboardings/onboarding/:action/:boardingId',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:onboardings:create'],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    component: NewOffboarding,
    pageLayout: EmptyPageLayout,
    parentPath: '/onoffboardings',
    path: '/onoffboardings/offboarding/:action',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:offboardings:create'],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    component: NewOffboarding,
    pageLayout: EmptyPageLayout,
    parentPath: '/onoffboardings',
    path: '/onoffboardings/offboarding/:action/:boardingId',
    meta: {
      auth: true,
      permissionsNeeded: ['turbine:offboardings:create'],
      plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM],
    },
  },
  {
    pageTitle: 'Employee profile',
    component: PeopleUserProfile,
    parentPath: '/onoffboardings',
    path: '/onoffboardings/profile/:employeeId',
    meta: {
      auth: true,
      featureFlagsNeeded: [TURBINE_PEOPLE_USER_PROFILES],
    },
  },
  {
    pageTitle: 'Edit Employee profile',
    component: PeopleUserProfileEdit,
    parentPath: '/onoffboardings',
    path: '/onoffboardings/profile-edit/:employeeId',
    meta: {
      auth: true,
      featureFlagsNeeded: [TURBINE_PEOPLE_EDIT_USER_PROFILES],
      permissionsNeeded: ['employee_profile:read', 'employee_profile:update'],
    },
  },
  {
    component: OAuth,
    pageLayout: EmptyPageLayout,
    parentPath: '/oauth/zoom/install',
    path: '/oauth/zoom/install',
    meta: { auth: true, plansNeeded: [PLANS.BASIC, PLANS.CORE, PLANS.PREMIUM] },
  },
  OnOffboardingRoute,
];
const enrollmentRoutes = [EnrollmentRoute];
const validRoutes = featureFlags =>
  [...applicationRoutes, ...enrollmentRoutes].filter(
    route =>
      isFeatureActive(route.path, featureFlags, FEATURE_FLAG_CONFIG) &&
      isFeatureActive(route.parentPath, featureFlags, FEATURE_FLAG_CONFIG)
  );

const Routes = ({
  location,
  _enrollmentCompleted,
  onEnrollmentComplete,
  enrollmentCompletedLoading = true,
  ...stateProps
}) => {
  const session = useSession();
  const featureFlags = useFeatureFlags();
  EnrollmentRoute.component = ENROLLMENT_ROUTES.component(
    onEnrollmentComplete,
    stateProps.plan
  );
  const { plan } = useCustomer();
  const isPremiumPlan = plan === PLANS.PREMIUM;

  return (
    <Suspense fallback={<LoadingScreen />}>
      {!enrollmentCompletedLoading && !!plan ? (
        <Switch location={location}>
          <Redirect
            exact
            from={REDIRECTS.FROM.LEGACY_DASHBOARD}
            to={REDIRECTS.TO.DASHBOARD}
          />
          <Redirect
            exact
            from="/"
            to={
              isPremiumPlan
                ? getRedirectURLFromConfig(
                    REDIRECTS.TO.ON_OFF_BOARDINGS.EMPLOYEES
                  )
                : REDIRECTS.TO.IT_SCORECARD
            }
          />
          <Redirect exact from="/home" to={REDIRECTS.TO.IT_SCORECARD} />
          <Redirect exact from="/welcome" to={REDIRECTS.TO.IT_SCORECARD} />
          <Redirect
            from={REDIRECTS.FROM.OFF_BOARDINGS}
            to={getRedirectURLFromConfig(
              REDIRECTS.TO.ON_OFF_BOARDINGS.OFFBOARDINGS
            )}
          />
          <Redirect
            from={REDIRECTS.FROM.ON_BOARDINGS}
            to={getRedirectURLFromConfig(
              REDIRECTS.TO.ON_OFF_BOARDINGS.ONBOARDINGS
            )}
          />
          <Redirect
            from={REDIRECTS.FROM.REPORTS}
            to={REDIRECTS.TO.DEVICE_MGMT.WITH.NO_FILTERS}
          />
          <Redirect
            from={REDIRECTS.FROM.LEGACY_DEVICE_MGMT}
            to={REDIRECTS.TO.DEVICE_MGMT.WITH.NO_FILTERS}
          />
          {isPremiumPlan && (
            <Redirect
              exact
              from={REDIRECTS.FROM.IT_SCORECARD}
              to={getRedirectURLFromConfig(
                REDIRECTS.TO.ON_OFF_BOARDINGS.EMPLOYEES
              )}
            />
          )}
          {validRoutes(featureFlags).map(
            ({
              path,
              exact = true,
              meta,
              loading = LoadingScreen,
              error,
              ignoreGlobal,
              guards,
              component,
              getComponentWithFlags,
              pageLayout: PageLayout = BasePageLayout,
            }) => {
              // If the user passed a getComponentWithFlags function
              // we will call that passing it the feature flags.
              // The result of that will bet he component to render.
              const Component = getComponentWithFlags
                ? getComponentWithFlags(featureFlags)
                : component;
              return (
                <Route
                  exact={exact}
                  key={path}
                  path={path}
                  meta={{
                    featureFlags,
                    stateProps,
                    session,
                    ...meta,
                  }}
                  loading={loading}
                  error={error}
                  ignoreGlobal={ignoreGlobal}
                  guards={guards}
                  render={renderProps => (
                    <RouteErrorBoundary>
                      <PageLayout
                        headerProps={
                          meta?.headerProps ? meta.headerProps : undefined
                        }>
                        <Component {...renderProps} {...stateProps} />
                      </PageLayout>
                    </RouteErrorBoundary>
                  )}
                />
              );
            }
          )}
          <Route component={PageNotFound} ignoreGlobal />
        </Switch>
      ) : (
        <LoadingScreen />
      )}
    </Suspense>
  );
};

Routes.propTypes = {
  location: PropTypes.object.isRequired,
  userPermissions: PropTypes.array.isRequired,
  onEnrollmentComplete: PropTypes.func.isRequired,
  enrollmentCompleted: PropTypes.bool.isRequired,
  enrollmentCompletedLoading: PropTypes.bool.isRequired,
  plan: PropTypes.string,
};

const mapStateToProps = state => ({
  location: history.location,
  plan: customerPlanSelector(state),
  userPermissions: userPermissionsSelector(state).userPermissions,
});

export default connect(mapStateToProps)(Routes);
