import { useState, createContext, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const RequesterDetailsContext = createContext({});

export const RequesterDetailsProvider = ({
  children,
  initialRequesterDetails = null,
}) => {
  const [requesterDetails, setRequesterDetails] = useState(
    initialRequesterDetails
  );

  return (
    <RequesterDetailsContext.Provider
      value={{ requesterDetails, setRequesterDetails }}>
      {children}
    </RequesterDetailsContext.Provider>
  );
};

RequesterDetailsProvider.propTypes = {
  initialRequesterDetails: PropTypes.shape({
    customerId: PropTypes.string,
    requesterId: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

/** eslint-disable react-hooks/exhaustive-deps */
export const useSetRequesterDetails = (customerId, requesterId) => {
  const { setRequesterDetails } = useContext(RequesterDetailsContext);
  useEffect(() => {
    setRequesterDetails({ customerId, requesterId });
    return setRequesterDetails;
  }, [customerId, requesterId, setRequesterDetails]);
};

const useRequesterDetails = () => {
  const { requesterDetails } = useContext(RequesterDetailsContext);
  return requesterDetails;
};

export default useRequesterDetails;
