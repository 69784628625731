import { lazy } from 'react';
import { RequesterDetailsProvider } from '@turbine/components/CustomerEnrollment/helpers/hooks/useRequesterDetails';
const Enrollment = lazy(() => import('../components/Enrollment'));

const ENROLLMENT = {
  path: '/enrollment-setup',
  component: onEnrollmentComplete => () => {
    return (
      <RequesterDetailsProvider>
        <Enrollment onEnrollmentComplete={onEnrollmentComplete} />
      </RequesterDetailsProvider>
    );
  },
};

export default ENROLLMENT;
