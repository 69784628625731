import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { bemClassHelper as bemify } from '@electricjs/utils/helpers';
import {
  setCurrentLDUser,
  useFeatureFlag,
  useFeatureFlags,
  updateLDUser as updateLDUserFn,
} from '@electricjs/utils/hooks';
import size from 'lodash/size';
import Routes from './routes';
import ModalContainer from '@turbine/components/common/Modal/ModalContainer';
import ContextProviders from '@turbine/components/ContextProviders';
import { FETCH_ENROLLMENT_COMPLETED } from '@turbine/graphql/queries/enrollmentQueries';
import { getOnboardingCompleted } from '@turbine/redux/selectors/enrollmentSelector';
import { useSession } from '@turbine/helpers/hooks';
import { useUserDetails } from '@turbine/helpers/hooks/useUserDetails';
import initApp from '@turbine/redux/actions/initApp';
import { initPendo } from '@turbine/services/pendo';
import { browserHistory as history } from '@electricjs/utils/helpers';
import styled from 'styled-components';
import { getToken, ColorsV2 } from '@electricjs/arc';
import { LoadingScreen } from '@turbine/@arc/recipes/LoadingScreen';

import { formatUserDetails } from '@turbine/graphql/queries/initializationQueries';
import {
  launchDarklyClientSideID,
  REACT_APP_KUSTOMER_BRAND_ID,
  nodeEnv,
} from '@turbine/config';
import { REDIRECTS } from './redirectsConfig';
import {
  GOD_MODE,
  TURBINE_TEMPLATIZE_SAAS_APP_NOTES,
  TURBINE_SHOW_KUSTOMER_KNOWLEDGE_BASE_LINK,
} from '@turbine/constants/featureFlags';
import { setCustomerPlan } from '@turbine/redux/actions/customerPlanActions';
import { setCustomerEnrollment } from '@turbine/redux/actions/customerEnrollmentActions';
import { setCustomerActivation } from '@turbine/redux/actions/customerActivationActions';
import updateToGodPermissions from '@turbine/helpers/updateToGodPermissions';
import { userPermissionsSet } from '@turbine/redux/actions/userPermissionsActions';
import { permissionsSelector } from '@turbine/redux/selectors/userPermissionsSelector';
import {
  getAppAccount,
  selectHasChatAppState,
} from '@turbine/redux/applications';
import { USER_CREATED_AT_PATH } from '@turbine/constants/sessionConstants';

import 'react-datepicker/dist/react-datepicker.css';
import { useRouteReloader } from '@turbine/helpers/hooks/useRouteReloader';

const appClasses = bemify('app');

// We were lacking a bit of organization using queries results, states and some variables.
// Moved some queries to this hooks (we can move them to another file if necessary) to get more
// control, and also to make the component easier to read.
const useEnrollmentStatus = customerId => {
  const [enrollmentStatus, setEnrollmentStatus] = useState();
  const { data, error, loading, refetch } = useQuery(
    FETCH_ENROLLMENT_COMPLETED,
    {
      variables: { customerId },
    }
  );
  useEffect(() => {
    setEnrollmentStatus({ data, error, loading, refetch });
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps
  return enrollmentStatus;
};

let defaultLDInitialized = false;

const StyledMainContent = styled.div`
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
  background: ${getToken(ColorsV2.BACKGROUND)};
  .et-banner {
    z-index: 100;
  }
`;

const RenderApp = () => {
  const [planLevel, setPlanLevel] = useState('');
  const [customerActivatedAt, setCustomerActivatedAt] = useState(null);
  const [ldInitialized, setLDInitialized] = useState(defaultLDInitialized);
  const [enrollmentCompleted, setEnrollmentCompleted] = useState(false); // customerOnBoardingCompleted
  /** redux state */
  const dispatch = useDispatch();
  const permissions = useSelector(permissionsSelector);
  const { hasChatApp: hasChatApp } = useSelector(selectHasChatAppState);
  /** session context */
  const {
    customerId,
    userId,
    token: accessToken,
    user,
    userRoles,
    isAdmin,
  } = useSession();

  /** Enrollment and user details */
  const enrollmentStatus = useEnrollmentStatus(customerId);
  const userDetails = useUserDetails(customerId, userId);

  const hasGodMode = useFeatureFlag(GOD_MODE);
  const hasSaasAppNotes = useFeatureFlag(TURBINE_TEMPLATIZE_SAAS_APP_NOTES);
  const hasKustomerWidget = useFeatureFlag(
    TURBINE_SHOW_KUSTOMER_KNOWLEDGE_BASE_LINK
  );

  const initFeatureFlags = useCallback(async (userId, userAttributes) => {
    await setCurrentLDUser(userId, launchDarklyClientSideID, userAttributes);
    setLDInitialized(true);
    defaultLDInitialized = true;
  }, []);

  /** effects */
  /** initialize feature flags */
  useEffect(() => {
    if (!ldInitialized && userDetails) {
      if (!userDetails.loading && (userDetails.data || userDetails.error)) {
        if (!userDetails.error) {
          const formattedUserDetails = formatUserDetails(userDetails.data);
          // If the user does not have a valid ID or Auth0 ID, then log them out.
          if (userId && !formattedUserDetails?.custom?.auth0Id) {
            history.push(nodeEnv.REACT_APP_AUTH0_LOGOUT_URL);
          }
          initFeatureFlags(userId, formattedUserDetails);
        } else {
          initFeatureFlags(userId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, userId, ldInitialized]);

  useEffect(() => {
    const updateLDUser = async userAttributes =>
      await updateLDUserFn(userId, userAttributes);

    if (ldInitialized && userDetails) {
      const formattedUserDetails = formatUserDetails(userDetails.data);
      formattedUserDetails && updateLDUser(formattedUserDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, userDetails]);

  /** initialize pendo */
  useEffect(() => {
    if (ldInitialized && userDetails && !userDetails.error && planLevel) {
      initPendo({
        userId,
        customerId,
        turbineInviteDate: user[USER_CREATED_AT_PATH],
        role: userRoles,
        customerName: userDetails.data?.customers?.name,
        userEmail: userDetails.data?.employees?.email,
        planLevel,
        activatedAt: customerActivatedAt,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldInitialized, userDetails, planLevel]);

  /** Add Kustomer Widget */
  useEffect(() => {
    if (hasKustomerWidget && window.Kustomer) {
      window.Kustomer.start({ brandId: REACT_APP_KUSTOMER_BRAND_ID });
    }
  }, [hasKustomerWidget]);

  /** update permissions */
  useEffect(() => {
    if (ldInitialized) {
      initApp({
        dispatch,
        accessToken,
        customerID: customerId,
        isAdmin,
        hasSaasAppNotes,
      });
      if (hasGodMode) {
        const updatedPermissions = updateToGodPermissions(permissions);
        dispatch(userPermissionsSet(updatedPermissions));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ldInitialized]);

  useEffect(() => {
    dispatch(
      getAppAccount({
        customerID: customerId,
      })
    );
  }, []);

  /** set customer enrollment application state */
  useEffect(() => {
    if (enrollmentStatus && enrollmentStatus.data) {
      const {
        customers: { plan, activated_at },
      } = enrollmentStatus.data;
      dispatch(setCustomerPlan(plan));
      dispatch(
        setCustomerActivation({
          isActive: !!activated_at,
          activatedAt: activated_at,
        })
      );
      setPlanLevel(plan);
      setCustomerActivatedAt(activated_at);
      const customerOnBoardingCompleted = enrollmentStatus.error // TODO: Redirect to error page
        ? true
        : getOnboardingCompleted(enrollmentStatus.data);
      dispatch(setCustomerEnrollment(customerOnBoardingCompleted));
      setEnrollmentCompleted(customerOnBoardingCompleted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentStatus]);

  // **** Route interceptor **** //
  useRouteReloader(history, nodeEnv);

  // route guards work synchronously so we need to wait for FF initialization
  // this avoid redirecting to error page
  const featureFlags = useFeatureFlags();
  if (!size(featureFlags)) {
    // TODO: https://electricops.atlassian.net/browse/IA1-2849
    return <LoadingScreen />;
  }

  return (
    <div className={appClasses('wrapper')} data-testid="app">
      <div className={appClasses('container')} data-testid="layout">
        <ContextProviders
          enrollmentCompleted={enrollmentCompleted}
          hasChatApp={hasChatApp}>
          <StyledMainContent
            id="app-main-content"
            className="main-content"
            data-testid="main-content">
            <Routes
              onEnrollmentComplete={() => {
                enrollmentStatus && enrollmentStatus.refetch();
                history.push(REDIRECTS.TO.IT_SCORECARD);
              }}
              enrollmentCompleted={enrollmentCompleted}
              enrollmentCompletedLoading={!!enrollmentStatus?.loading}
            />
          </StyledMainContent>
          <ModalContainer />
        </ContextProviders>
      </div>
    </div>
  );
};

export default RenderApp;
