import queryString from 'query-string';
import { ENROLLMENT_ROUTES } from '@turbine/components/CustomerEnrollment';

export const getRedirectURLFromConfig = (config, params) => {
  if (!config.URL) {
    return config;
  }
  const oldHash = queryString.parse(window.location.hash);
  const routeURL = config.URL;
  const newHash = {
    ...oldHash,
    ...config.PARAMS,
    ...params,
  };
  return `${routeURL}#${queryString.stringify(newHash)}`;
};

export const REDIRECTS = {
  FROM: {
    CONFIGURATION: '/configuration',
    LEGACY_DEVICE_MGMT: '/device-management/macs',
    LEGACY_DASHBOARD: '/company/management/dashboard',
    REPORTS: '/reports',
    OFF_BOARDINGS: '/offboardings',
    ON_BOARDINGS: '/onboardings',
    OFFBOARDING_STATUS: '/offboarding/status/',
    ONBOARDING_STATUS: '/onboarding/status/',
    NEW_OFFBOARDING: '/onoffboardings/offboarding/new',
    NEW_ONBOARDING: '/onoffboardings/onboarding/new',
    IT_SCORECARD: '/it-score-card',
  },
  TO: {
    DASHBOARD: '/dashboard',
    IT_SCORECARD: '/it-score-card',
    LOCATIONS: '/locations',
    APPLICATIONS: '/applications',
    APPLICATION_CONFIGURATION: '/applications/configure',
    ON_OFF_BOARDINGS: {
      EMPLOYEES: {
        URL: '/onoffboardings',
        PARAMS: {
          activeTab: 'employees',
        },
      },
      ONBOARDINGS: {
        URL: '/onoffboardings',
        PARAMS: {
          activeTab: 'onboardings',
        },
      },
      OFFBOARDINGS: {
        URL: '/onoffboardings',
        PARAMS: {
          activeTab: 'offboardings',
        },
      },
    },
    ENROLLMENT_SETUP: ENROLLMENT_ROUTES.path,
    ROOT: '/it-score-card',
    DEVICE_MGMT: {
      WITH: {
        STORED_DEVICES: {
          URL: '/device-mgmt',
          PARAMS: {
            activeTab: 'stored-devices',
          },
        },
        NO_FILTERS: '/device-mgmt',
        ALL_MACS: {
          URL: '/device-mgmt',
          PARAMS: {
            osFilter: 'mac',
          },
        },
        AT_RISK_MACS: {
          URL: '/device-mgmt',
          PARAMS: {
            statusFilters: 'firewall,filevault,gatekeeper',
            osFilter: 'mac',
          },
        },
        ALL_PCS: {
          URL: '/device-mgmt',
          PARAMS: {
            osFilter: 'windows',
          },
        },
        AT_RISK_PCS: {
          URL: '/device-mgmt',
          PARAMS: {
            osFilter: 'windows',
            statusFilters: 'bitlocker,firewall',
          },
        },
        NON_REPORTING_DEVICES: {
          URL: '/device-mgmt',
          PARAMS: {
            statusFilters: 'non-reporting',
          },
        },
        NON_REPORTING_PCS: {
          URL: '/device-mgmt',
          PARAMS: {
            statusFilters: 'non-reporting',
            osFilter: 'windows',
          },
        },
        NON_REPORTING_MACS: {
          URL: '/device-mgmt',
          PARAMS: {
            statusFilters: 'non-reporting',
            osFilter: 'mac',
          },
        },
        MISSING_FIREWALL: {
          URL: '/device-mgmt',
          PARAMS: {
            statusFilters: 'firewall,non-reporting',
          },
        },
        MISSING_ENCRYPTION: {
          URL: '/device-mgmt',
          PARAMS: {
            statusFilters: 'filevault,bitlocker,non-reporting',
          },
        },
        MISSING_GATEKEEPER: {
          URL: '/device-mgmt',
          PARAMS: {
            statusFilters: 'gatekeeper,non-reporting',
            osFilter: 'mac',
          },
        },
        EMAIL_MODAL_OPEN: {
          URL: '/device-mgmt',
          PARAMS: {
            email: 'true',
          },
        },
        DEVICE_DETAIL_OPEN: {
          URL: '/device-mgmt',
        },
      },
    },
  },
};
